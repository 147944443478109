import React, { useState } from 'react';
import './Home.css';
import profileImage1 from '../assets/2S0A2615.jpeg';
//import profileImage2 from '../assets/2S0A2325.jpeg';
import uminhoLogo from '../assets/uminho-logo.png';

function Home() {

    const miguelRochaLink = "https://www.ceb.uminho.pt/People/Details/19c61a9c-8d3f-4880-bbf6-8e31dc3f3fc1";
    const vitorAlvesLink = "https://algoritmi.uminho.pt/user/victor-manuel-rodrigues-alves/";
    const pedroMiguelLink = "https://www.linkedin.com/in/pedro-miguel-neves-4670342/?originalSubdomain=pt";

    const [selectedVideo, setSelectedVideo] = useState(null);

  return (
    <div className="home">
      <div className="profile-section">
        <div className="profile-image">
          <img src={profileImage1} alt="Profile" />
        </div>
        <h1>Guilherme <strong>Viveiros</strong></h1>
        <p className="tagline">
          I like to build intelligent solutions for complex problems 🧠 💻 🚀
        </p>
        <p className="sndline">
          My research interests are in few-shot learning, self-supervised
          representation learning, and generative modeling. 
        </p>
        <div className="social-links">
          <a href="https://github.com/GuilhermeViveiros" target="_blank" rel="noopener noreferrer">
            <svg viewBox="0 0 24 24" className="social-icon">
              <path d="M12 2C6.477 2 2 6.477 2 12c0 4.42 2.865 8.17 6.839 9.49.5.092.682-.217.682-.482 0-.237-.008-.866-.013-1.7-2.782.604-3.369-1.34-3.369-1.34-.454-1.156-1.11-1.464-1.11-1.464-.908-.62.069-.608.069-.608 1.003.07 1.531 1.03 1.531 1.03.892 1.529 2.341 1.087 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.11-4.555-4.943 0-1.091.39-1.984 1.029-2.683-.103-.253-.446-1.27.098-2.647 0 0 .84-.269 2.75 1.025A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.294 2.747-1.025 2.747-1.025.546 1.377.203 2.394.1 2.647.64.699 1.028 1.592 1.028 2.683 0 3.842-2.339 4.687-4.566 4.935.359.309.678.919.678 1.852 0 1.336-.012 2.415-.012 2.743 0 .267.18.578.688.48C19.138 20.167 22 16.418 22 12c0-5.523-4.477-10-10-10z"/>
            </svg>
          </a>
          <a href="https://linkedin.com/in/guilherme-viveiros-28985418b/" target="_blank" rel="noopener noreferrer">
            <svg viewBox="0 0 24 24" className="social-icon">
              <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"/>
            </svg>
          </a>
          <a href="https://x.com/Guilherme_PT1" target="_blank" rel="noopener noreferrer">
            <svg viewBox="0 0 24 24" className="social-icon">
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
            </svg>
          </a>
          <a href="mailto:aviveiros2012@gmail.com" target="_blank" rel="noopener noreferrer">
            <svg viewBox="0 0 24 24" className="social-icon">
              <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z"/>
              <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z"/>
            </svg>
          </a>
        </div>
      </div>
      
      <hr className="section-divider" />

      <div className="timeline">
       
        
        <div className="timeline-item">
          <div className="year-container">
            <div className="year">2023-Present</div>
            <div className="institution-logo">
              <img src={require('../assets/dotmoovs-symbol_blue-white-background.jpg')} alt="Dotmoovs" />
            </div>
          </div>
          <div className="content">
            <p className="first-paragraph">
              <strong>Lead Machine Learning Engineer</strong> at Dotmoovs,
              where I lead the Computer Vision team and developed innovative
              AI-driven solutions to enhance user engagement and streamline training.
              This work includes managing in-house data labeling, neural network training
              and model deployment into production.
            </p>

            <p>Our latest project focuses on a foundational model trained on
              proprietery 2.5M+ user movement videos, which significantly outperformed single-task
              models by capturing rich representations from nuanced biomechanical patterns. 
              This model's versatility has allowed us to significantly expand our catalog of available exercises
              and features, enhancing user experience and engagement.
            </p>

            <p>Our long-term vision is to create a full self-assistant for physical activity that
              adapts to users' individual training needs as our user base grows. 
              We are committed to delivering innovative, real-time feedback and ensuring users get an immersive,
              supportive training environment.
            </p>

            <div className="media-container">
              <div className="video-section">
                {['squat_posture_v'].map((videoName) => (
                  <div className="video-item" key={videoName} onClick={() => setSelectedVideo(videoName)}>
                    <video 
                      loop 
                      muted 
                      playsInline
                      poster={require('../assets/sports_assets/squat_poster.png')}
                    >
                      <source src={require(`../assets/sports_assets/${videoName}.mp4`)} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <div className="video-overlay">
                      <p>Real-Time Virtual Assistant + <strong>Posture Correction</strong></p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="presentations-section">
                <div className="presentation-item">
                  <a href="https://www.youtube.com/watch?v=eSq9yRjM6zg&t=2259s&ab_channel=StartupPortugal" target="_blank" rel="noopener noreferrer">
                    <img src={require('../assets/sports_assets/presentation1.png')} alt="Conference presentation" />
                    <div className="presentation-overlay">
                      <p>Diving into Deep Learning</p>
                    </div>
                  </a>
                </div>
                <div className="presentation-item">
                  <a href="https://www.youtube.com/watch?v=-RNqtR9b2mA&t=1s&ab_channel=dotmoovs" target="_blank" rel="noopener noreferrer">
                    <img src={require('../assets/sports_assets/presentation2.png')} alt="Conference presentation" />
                    <div className="presentation-overlay">
                      <p>AI + Blockchain Talk</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="timeline-item">
          <div className="year-container">
            <div className="year">2021-2023</div>
            <div className="institution-logo">
              <img src={require('../assets/dotmoovs-symbol_blue-white-background.jpg')} alt="Dotmoovs" />
            </div>
          </div>
          <div className="content">
            <p>
              <strong>Machine Learning Engineer</strong> at Dotmoovs, where I architected computer
              vision systems for real-time freestyle football analysis, achieving optimal accuracy in player/ball
              detection and touch/trick classification. Developed and fine-tuned neural networks on proprietary
              datasets of 100K+ freestyle videos to recognize diverse tricks and touches.
            </p>
            
            <div className="video-grid">
              {['ff1', 'ff2', 'ff3', 'dance'].map((videoName) => (
                <div className="video-item" key={videoName} onClick={() => setSelectedVideo(videoName)}>
                  <video 
                  loop
                  muted
                  playsInline
                  poster={require(`../assets/placeholders/${videoName}.png`)}
                  >
                    <source src={require(`../assets/sports_assets/${videoName}.mp4`)} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <div className="video-overlay">
                    <p>{videoName === 'ff1' ? 'Maradona Retrieval demo' :
                       videoName === 'ff2' ? 'Real-time detection and tracking' :
                       videoName === 'ff3' ? 'Freestyle Touch & Trick Classification' :
                       videoName === 'dance' ? 'Dance Similarity System' :
                       'Football Skills Analysis'}</p>
                  </div>
                </div>
              ))}

              {/* Video Modal */}
              {selectedVideo && (
                <div className="video-modal" onClick={() => setSelectedVideo(null)}>
                  <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <video autoPlay controls onClick={e => e.stopPropagation()}>
                      <source src={require(`../assets/sports_assets/${selectedVideo}.mp4`)} type="video/mp4" />
                    </video>
                    <button className="close-button" onClick={() => setSelectedVideo(null)}>×</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="timeline-item">
          <div className="year-container">
            <div className="year">2020-2020</div>
            <div className="institution-logo nearsoft-logo">
              <img src={require('../assets/nearsoft_pt_logo.png')} alt="Nearsoft" />
            </div>
          </div>
          <div className="content">
            <p>
              As a Research Intern at Nearsoft, implemented temporal graph neural networks for fraud detection,
              reducing false positives by 17% while maintaining strong precision. This graph-based approach surpassed
              the bank's production ARIMA models by enabling real-time pattern detection across banking networks.
            </p>
          </div>
        </div>

        <div className="timeline-item">
          <div className="year-container">
            <div className="year">2019-2021</div>
            <div className="institution-logo">
              <img src={uminhoLogo} alt="University of Minho" />
            </div>
          </div>
          <div className="content">
            <p>
            <strong>Genius Program Grant </strong> from Altice Labs, where I conducted innovative research during my MSc at the University of Minho. I've collaborated with <a href={pedroMiguelLink} target="_blank" rel="noopener noreferrer" className="highlight-link">Pedro Miguel</a> on a project focused on optimizing channel allocation in wireless networks, specifically applying machine learning to improve the agility of robotics in simulated environments. This work, conducted under the guidance of <a href={vitorAlvesLink} target="_blank" rel="noopener noreferrer" className="highlight-link">Vítor Alves</a>, transitioned successfully from simulation to real-world applications.
            </p>
            <p>
              Our method achieved higher performance margins than the existing production methods at the time. The results were particularly promising in densely populated scenarios, where strategic channel selection and resource planning are critical for maintaining reliable connections and operational efficiency.
            </p>
          </div>
        </div>

        <div className="timeline-item">
          <div className="year-container">
            <div className="year">2016-2019</div>
            <div className="institution-logo">
              <img src={uminhoLogo} alt="University of Minho" />
            </div>
          </div>
          <div className="content">
            <p>BSc at the University of Minho with a major in computer science. This is where I first got into deep learning, attending <a href={miguelRochaLink} target="_blank" rel="noopener noreferrer" className="highlight-link">Miguel Rocha's</a> and <a href={vitorAlvesLink} target="_blank" rel="noopener noreferrer" className="highlight-link">Vitor Alves's</a> classes and reading groups.</p>
          </div>
        </div>
      </div>

      <hr className="section-divider" />

              
      <div className="featured-talks">
        <h2>Featured Talks</h2>
        <div className="talks-grid">
          <div className="talk-item">
            <div className="talk-image">
              <a href="https://www.youtube.com/watch?v=eSq9yRjM6zg&t=2259s&ab_channel=StartupPortugal" target="_blank" rel="noopener noreferrer">
                <img src={require('../assets/sports_assets/presentation1.png')} alt="Deep Learning" />
              </a>
            </div>
            <div className="talk-content">
              <h3>Dive into Deep Learning</h3>
              <p>Deep dive into deep learning on industry applications (EN)</p>
            </div>
          </div>

          <div className="talk-item">
            <div className="talk-image">
              <a href="https://www.youtube.com/watch?v=UwWv_q4PN-0&t=29s&ab_channel=VMTV" target="_blank" rel="noopener noreferrer">
                <img src={require('../assets/sports_assets/dmp.png')} alt="AI and Dotmoovs" />
              </a>
            </div>
            <div className="talk-content">
              <h3>AI and Dotmoovs</h3>
              <p>Discuss how Dotmoovs is using AI to improve physical training (PT)</p>
            </div>
          </div>

          <div className="talk-item">
            <div className="talk-image">
              <a href="https://www.youtube.com/watch?v=-RNqtR9b2mA&t=1s&ab_channel=dotmoovs" target="_blank" rel="noopener noreferrer">
                <img src={require('../assets/sports_assets/presentation2.png')} alt="AI + Blockchain" />
              </a>
            </div>
            <div className="talk-content">
              <h3>AI + Blockchain</h3>
              <p>Discuss the interplay between Artificial Intelligence & Blockchain (EN)</p>
            </div>
          </div>

          
        </div>
      </div>
      
      <hr className="section-divider" />

      <div className="pet-projects">
        <h2>pet projects</h2>
        <ul className="project-list">
          
          <li className="project-item">
            <img src={require('../assets/projects/RMDE.png')} alt="Project 1" />
            <div>
              <p>
                <a href="https://github.com/GuilhermeViveiros/Movement-Detection/blob/master/README.md" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>
                  Real-time Movement-Detection and Execution
                </a> aims to create a gesture-based interaction system for computers using deep learning.
                The main objective is to develop a neural network pipeline that detects and interprets user gestures in real time,
                enabling hands-free control in scenarios where traditional input methods are impractical.
              </p>
            </div>
          </li>

          <li className="project-item"> 
          <img src={require('../assets/projects/snapflow.png')} alt="Project 3" />
            <div>
              <p>
                <a href="https://github.com/GuilhermeViveiros/SnapFlow.git" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>
                  SnapFlow
                </a> is a real-time photo-syncing tool that uses feature extraction and representation learning
                to organize, label, and sync photos between devices and users. It automatically detects
                and clusters faces, creating labeled person entries for seamless sharing and management.
                Currently, database setup requires manual processing, with plans for automated real-time updates
                in future versions.
              </p>
            </div>
          </li>
          
          <li className="project-item">
            <img src={require('../assets/projects/diffusion_process.png')} alt="Project 3" />
            <div>
              <p> 
                <a href="https://github.com/GuilhermeViveiros/diffusion" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>
                  stable-diffusion-toy
                </a> is a custom-built diffusion model, implemented from scratch to generate images using the One Piece and MNIST datasets. 
                The primary goal was to gain a thorough understanding of diffusion models by diving deep into the underlying mathematics and coding each component manually.
              </p>
            </div>
          </li>

          <li className="project-item"> 
          <img src={require('../assets/projects/stateqube.png')} alt="Project 3" />
            <div>
              <p>
                <a href="https://github.com/GuilhermeViveiros/StateQube" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>
                  StateQube
                </a> is an AI research assistant Chrome extension for interpreting financial statements.
                It leverages LangChain and a Retrieval-Augmented Generation (RAG) framework to extract 
                and analyze financial data from sources like Yahoo Finance, using a custom LLM pipeline 
                and semantic search. With added context from Warren Buffett’s insights, StateQube 
                enables interactive, in-browser financial analysis for users.
              </p>
            </div>
          </li>
          
          <p>Building from first principles, I’ve created dedicated repositorys for deep dives into core machine learning topics: </p>
          

          <li className="project-item"> 
          <img src={require('../assets/projects/MLREPO.png')} alt="Project 3" />
            <div>
              <p>
                <a href="https://github.com/GuilhermeViveiros/ML-Board/tree/main" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>
                  MLBoard
                </a> This repository offers a hands-on exploration of Machine Learning, showcasing projects in Computer Vision, Natural Language Processing, and Reinforcement Learning. Each project includes detailed implementations of key models and techniques, such as attention mechanisms, variational autoencoders (VAEs), VAE interpolations, and Vector Quantized VAEs (VQ-VAEs) with insights into learning and interpreting codebooks, among others.
              </p>
            </div>
          </li>
          <li className="project-item"> 
          <img src={require('../assets/projects/prior.png')} alt="Project 3" />
            <div>
              <p>
                <a href="https://github.com/GuilhermeViveiros/Statistics/tree/main" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>
                Statistics repo
                </a> explores key statistical concepts like probability distributions, Bayesian methods, and uncertainty analysis through practical notebooks. Topics include Bayesian linear regression, sales analysis with uncertainty, and probability distributions.
              </p>
            </div>
          </li>
          

        </ul>
      </div>

    </div>
  );
}

export default Home; 